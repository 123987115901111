<template>
    <div>
        <div class="right-menu shipping-right billSection">

            <div class="db-blue-section" v-loading.fullscreen.lock="isLoadingBill">

                <subHeader pageName="EDIT BILL"/>

                <ValidationObserver ref="updateBillObserver">
                    <div class="db-container">        
                        <div class="billFormMainBlock mt-0">
                            <form id="update-bill-information" @submit.prevent="validate('update')">
                                <div class="row">

                                    <!-- vendor name block starts here -->
                                    <div class="col-md-12">
                                        <div class="formContentBox" style="margin-bottom: 0px;" id="vendor-selection-section">
                                            <label for="vendor-selection">Vendor <span>*</span></label>
                                        </div>
                                        <div class="vueSelectBlock" style="margin-bottom: 20px;">
                                            <ValidationProvider name="vendor" rules="required" v-slot="{ errors }">
                                                <multiselect
                                                    id="vendor-selection" 
                                                    v-model="selectedVendor"
                                                    :options="vendors"
                                                    placeholder="Vendor"
                                                    label="name"
                                                    track-by="id"
                                                    :open-direction="'bottom'"
                                                    :searchable="true"
                                                    @search-change="loadVendors"
                                                    :loading="vendorsLoading"
                                                >
                                                    <span slot="noResult">
                                                        No vendor found matching the searched keyword.
                                                    </span>
                                                </multiselect>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- vendor name block ends here -->
                
                                    <!-- bill no. block starts here -->
                                    <div class="col-md-6">
                                        <div class="formContentBox">
                                            <ValidationProvider name="bill no." rules="required|max:250" v-slot="{ errors }">
                                                <label for="billNumber">Bill No. <span>*</span></label>
                                                <input 
                                                    type="text" 
                                                    id="billNumber" 
                                                    name="billNumber" 
                                                    class="form-control" 
                                                    placeholder="Bill no."
                                                    v-model="bill.bill_no"
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- bill no. block ends here -->
                
                                    <!-- order no. block starts here -->
                                    <div class="col-md-6">
                                        <div class="formContentBox">
                                            <ValidationProvider name="order no." rules="max:250" v-slot="{ errors }">
                                                <label for="orderNumber">Order No. </label>
                                                <input 
                                                    type="text" 
                                                    id="orderNumber" 
                                                    name="orderNumber" 
                                                    class="form-control" 
                                                    placeholder="Order no."
                                                    v-model="bill.order_no"
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- order no. block ends here -->
                
                                    <!-- bill date block starts here -->
                                    <div class="col-md-6">
                                        <div class="formContentBox">
                                            <label for="billDate">Bill Date <span>*</span></label>
                                            <ValidationProvider name="bill date" rules="required" v-slot="{ errors }">
                                                <input 
                                                    type="date" 
                                                    id="billDate" 
                                                    name="billDate" 
                                                    class="form-control" 
                                                    onkeydown="return false" 
                                                    @input="checkDate()"
                                                    :max="minDate"
                                                    v-model="bill.bill_date"
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- bill date block ends here -->
                
                                    <!-- due date block starts here -->
                                    <div class="col-md-6">
                                        <div class="formContentBox">
                                            <label for="dueDate">Due Date <span>*</span></label>
                                            <ValidationProvider name="due date" rules="required" v-slot="{ errors }">
                                                <input 
                                                    type="date" 
                                                    id="dueDate" 
                                                    name="dueDate" 
                                                    class="form-control" 
                                                    onkeydown="return false" 
                                                    @input="checkDate()" 
                                                    :min="bill.bill_date"
                                                    v-model="bill.due_date"
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- due date block ends here -->

                                </div>
                            </form>
                        </div>

                        <!-- bill items block starts here -->
                        <div class="itemDetailTable">
                            <div class="billTableresponsiveBlock">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Item Details *</th>
                                            <th>Bill Type *</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Tax</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in items" :key="index">
                                            <td :id="index" class="itemDetailForm"> 
                                                <div class="billItemDetailBox">
                                                    <ValidationProvider name="product" :vid="`item-name-`+index" rules="required" v-slot="{ errors }">
                                                        <multiselect
                                                            v-model="item.name"
                                                            :id="`bill-item-name-`+index"
                                                            placeholder="Type to search a product"
                                                            open-direction="bottom"
                                                            :options="products"
                                                            :multiple="false"
                                                            :searchable="true"
                                                            label="name"
                                                            track-by="name"
                                                            :loading="multiLoader"
                                                            :internal-search="false"
                                                            :clear-on-select="true"
                                                            :close-on-select="true"
                                                            :show-no-results="true"
                                                            :hide-selected="false"
                                                            :allow-empty="false"
                                                            deselect-label="Can't remove the selected item"
                                                            @search-change="loadProducts"
                                                            @select="handleSelected($event,index)"
                                                            @remove="handleRemove($event,index)">
                                                            <span slot="noResult">Nothing found against the searched query.</span>
                                                        </multiselect>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </td>
                                            <td class="itemDetailForm">
                                                <div class="billItemDetailBox">
                                                    <ValidationProvider name="bill type" :vid="`item-bill-type-`+index" rules="required" v-slot="{ errors }">
                                                        <multiselect 
                                                            v-model="item.bill_type" 
                                                            :id="`item-type-`+index"
                                                            tag-placeholder="Add This as New Bill Type" 
                                                            placeholder="Search Bill Type" 
                                                            :options="billTypes" 
                                                            :taggable="true" 
                                                            @tag="addTag"
                                                        >
                                                        </multiselect>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </td>
                                            <td>
                                                <el-input-number 
                                                    size="small" 
                                                    v-model="item.qty"
                                                    @change="calItemAmountQty(index)" 
                                                    :min="1"
                                                ></el-input-number>
                                            </td>
                                            <td>
                                                <ValidationProvider 
                                                    name="rate" 
                                                    :vid="`item-rate-`+index" 
                                                    :rules="{required:true,regex:'^[0-9]*[.]?[0-9]*$',max_value:9999999999999}" 
                                                    v-slot="{ errors }"
                                                >
                                                    <input 
                                                        type="number" 
                                                        step="0.01" 
                                                        class="form-control"
                                                        v-model="item.rate" 
                                                        @change="rateChangeEffect(index)"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </td>
                                            <td>
                                                <el-input-number 
                                                    size="small" 
                                                    v-model="item.tax" 
                                                    @change="taxChangeHandle(index)" 
                                                    :min="0" 
                                                    :max="100"
                                                ></el-input-number>
                                            </td>
                                            <td>
                                                <ValidationProvider 
                                                    name="amount" 
                                                    :vid="`item-amount-`+index" 
                                                    :rules="{required:true,max_value:9999999999999}" 
                                                    v-slot="{ errors }"
                                                >
                                                    <input 
                                                        type="number" 
                                                        step="0.01" 
                                                        :id="`bill-item-amount-`+index" 
                                                        class="form-control" 
                                                        v-model="item.amount" 
                                                        disabled
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </td>
                                            <td>
                                                <button @click="removeLine(index)">
                                                    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"/>
                                                        <path d="M0 0h48v48H0z" fill="none"/>
                                                    </svg> Delete
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="addTableRowBtn">
                                <button @click="addLine">ADD ANOTHER LINE</button>
                            </div>
                        </div>
                        <!--bill items block ends here -->
        
                        <!-- sub total, discount and net total block starts here -->
                        <div class="subTotalContainer">
                            <div class="subTotalBox">
                                <div class="txtFlexBlock">
                                    <p>Sub Total</p>
                                    <p>{{ settings.currency_unit ? calsubTotal ? parseFloat(calsubTotal).toLocaleString('ur-PK', {style: 'currency',currency: settings.currency_unit,minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0 : '--' }}</p>
                                </div>
                                <div class="txtFlexBlock">
                                    <p>Discount</p>
                                    <p>
                                        <el-input-number 
                                            size="small" 
                                            v-model="bill.discount"  
                                            @change="calDiscountedTotal()"  
                                            :min="0" 
                                            :max="100"
                                        ></el-input-number>
                                    </p>
                                </div>
                                <div class="txtFlexBlock" style="margin-bottom: 0px">
                                    <p>Total</p>
                                    <p>{{ settings.currency_unit ? calDiscountedPrice ? parseFloat(calDiscountedPrice).toLocaleString('ur-PK', {style: 'currency',currency: settings.currency_unit,minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0 : '--' }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- sub total, discount and net total block ends here -->
        
                        <!-- bill description & attachment block starts here -->
                        <div class="attachFileRow">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="formContentBox bankDescription">
                                        <ValidationProvider ref="formProvider" name="description/note" rules="max:3000" v-slot="{ errors }">
                                            <label for="billDescription">Notes or description for bill</label>
                                            <textarea 
                                                name="billDescription" 
                                                id="billDescription" 
                                                placeholder="Bill description"
                                                v-model="bill.description"
                                            >
                                            </textarea>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ValidationProvider ref="formProvider" name="attachment" rules="mimes:jpg,png,jpeg,webp,pdf,xls,csv,docx,doc" v-slot="{ errors }">
                                        <div class="billDescription">
                                            <form>
                                                <label for="uploadBill">Attach file to Bill</label>
                                                <div class="fileUploaderNameBox" v-if="bill.attachment">
                                                    <div class="fileUploader">
                                                        <div class="fileName" >
                                                            <p>{{bill.attachment.name ? bill.attachment.name :bill.attachment}}</p>
                                                        </div>
                                                        <div class="fileAction" @click.prevent="removeAttachment('edit',$event)">
                                                            <div class="item loading-6" v-if="attachmentLoader">
                                                                <svg viewBox="25 25 50 50">
                                                                    <circle cx="50" cy="50" r="20"></circle>
                                                                </svg>
                                                            </div>
                                                            <div v-else>
                                                                <svg width="18" fill="#f00" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"/>
                                                                    <path d="M0 0h48v48H0z" fill="none"/>
                                                                </svg>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="formField" v-if="!bill.attachment">
                                                    <input 
                                                        type="file"
                                                        id="uploadBill"
                                                        ref="billFile"
                                                        name="uploadBill"
                                                        accept="image/png,image/jpg,image/jpeg,image/webp,application/pdf,application/msword,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        @change.prevent="changeImage"
                                                    >
                                                </div>
                                            </form>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <!-- bill description & attachment block ends here -->
        
                        <!-- save bill block starts here -->
                        <div class="addTableRowBtn">
                            <button @click.prevent="goBack()">CANCEL</button>
                            <loaderBtn v-if="isLoading"/>
                            <button 
                                form="update-bill-information" 
                                type="submit"
                                @click="validate('update')" 
                                v-else
                            >
                                SAVE
                            </button>
                        </div>
                        <!-- save bill block ends here -->
        
                    </div>
                </ValidationObserver>

            </div>

        </div>
    </div>
</template>
  
<script>
import {MessageBox, Message} from 'element-ui'
import _ from 'lodash';
import {mapGetters} from 'vuex';
import subHeader from "@/components/subHeader.vue";
import loaderBtn from "@/components/loaderBtn.vue";
  
export default {
    metaInfo() {
        return {
            title: 'Expenses/Purchases | Edit Bill | YeetCommerce',
        };
    },
    data: () => ({
        isLoading: false,
        isLoadingBill:false,
        vendors:[],
        tempVendors:[],
        vendorsLoading: false,
        vendorsTimer: false,
        billTypes:[
            "Cost Of Goods Sold",
            "IT & Internet Expenses",
            "Sales","Office Supplies",
            "Inventory Asset",
            "Equipments Purchased",
            "Uncategorized"
        ],
        tempBillTypes:[],
        selectedVendor:[],
        bill:{
            vendor_id:'',
            bill_no:'',
            order_no:'',
            bill_date:'',
            due_date:'',
            items:[],
            id:'',
            payment_terms:'',
            discount:0,
            sub_total:0,
            total:0,
            description:'',
            attachment:null,
        },
        tempItems:{
            variation_id:'',
            name:'',
            bill_type:'',
            qty:0,
            rate:0,
            tax:0,
            amount:0,
        },
        items:[
            {
            variation_id:'',
            name:'',
            bill_type:'',
            qty:0,
            rate:0,
            tax:0,
            amount:0,
            }
        ],
        itemsTemp:[],
        products:[],
        tempProducts:[],
        multiLoader:false,
        attachmentLoader:false,
        isTouched:false,
        attachmentValidations:{
            imgIsRequired:false,
            imgInvalidFormat:false,
            isNotImg:false,
        },
    }),
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storeBills:'bills_module/bills',
        }),
        calsubTotal(){
            
            let subTotal = "0.0";
    
            this.items.forEach(item=>{
    
                let itemAmount = (parseFloat(subTotal) + parseFloat(item.amount)).toFixed(2)
        
                subTotal = itemAmount
    
            })
    
            this.bill.sub_total = subTotal
    
            return this.bill.sub_total;
        },
        calDiscountedPrice(){
            
            let percent = parseFloat((this.bill.discount / 100) * this.bill.sub_total).toFixed(2)
    
            this.bill.total = parseFloat(this.bill.sub_total - percent).toFixed(2) 
            
            return this.bill.total
        },
        minDate(){

            var dtToday = new Date();

            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();

            if(month < 10){

                month = '0' + month.toString();

            }

            if(day < 10){

                day = '0' + day.toString();

            }

            var maxDate = year + '-' + month + '-' + day;

            return maxDate;

        },
    },
    components: {
        subHeader,
        loaderBtn
    },
    watch:{
        'settings':{
            handler:function(val){

                this.items[0].tax = val.tax;

            },
            deep:true
        },
        'selectedVendor':{
            handler:function(value){

                if(value){

                    this.isTouched = true

                }else{

                    this.isTouched = false

                }

            },deep:true
        },
        'bill':{
            handler:function(value){
                if(value.vendor_id || value.bill_no || value.order_no || value.bill_date
                || value.due_date || value.items.length > 0 || value.payment_terms
                || value.discount > 0 || value.sub_total > 0 || value.total > 0
                || value.description || value.attachment){

                    this.isTouched = true

                }else{

                    this.isTouched = false

                }
            },deep:true
        }
    },
    methods: {
        checkDate(){
    
            if(this.bill.due_date && this.bill.bill_date){
    
                var order_date = new Date(this.bill.bill_date);
                var deliver_date = new Date(this.bill.due_date);
        
                if(deliver_date < order_date){
                    
                    this.$notify({
                        type: 'warning',
                        title: 'Alert',
                        message: "Can't set due date before order date.",
                    });
                
                    this.bill.due_date = ''
        
                } 
    
            }
        },
        async removeAttachment(form,e){
    
            if(form == 'add'){
    
                this.bill.attachment = null
                this.$refs.billFile.value = ''
    
            }else if(form == 'edit'){
    
                let index = this.storeBills.findIndex(x=>x.id == this.bill.id);
                
                if(index !== -1 && this.storeBills[index].attachment){

                    this.attachmentLoader = true
        
                    let formData = new FormData();
        
                    formData.append('bill_id',this.storeBills[index].id);

                    try{
                        let res = await this.$axios.post('removeBillAttachment',formData)
                        if(res.data.status_code == "10591"){
            
                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });
            
                            this.attachmentLoader = false
            
                            this.bill.attachment = null;
            
                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);
            
                            this.$store.commit('bills_module/update_bills_list',res.data.bill);

                        }
                    }catch(error){

                        this.attachmentLoader = false

                        if(error.response){

                            if(error.response.data.error.includes("doesn't support")){

                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }

                }else{

                    this.bill.attachment = null;
        
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: "Attachment has been successfully removed.",
                    });
        
                }
            
            }
            
        },
        addTag(newTag){
            
            this.billTypes.push(newTag);
    
        },
        async changeImage(e){
    
            this.attachmentValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }
    
            if(e.target.files.length > 0){
            
                const file = e.target.files[0];
        
                if(file.type == 'image/jpeg' || 
                file.type == 'image/jpg' || 
                file.type == 'image/webp' || 
                file.type == 'image/png' ||
                file.type == 'application/pdf' ||
                file.type == 'application/msword' ||
                file.type == 'application/vnd.ms-excel' ||
                file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
        
                    this.bill.attachment = file;
        
                    this.attachmentValidations.imgIsRequired = false;
        
                }else if(file.type.includes('image/')){
                    
                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });
        
                    this.$refs.billFile.value = ''
        
                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image or document.",
                    }); 
        
                    this.$refs.billFile.value = ''
        
                }
            
            }
    
        },
        taxChangeHandle(index){
    
            if(this.items[index].tax > 0 && this.items[index].qty == 1){
    
                let percent = (this.items[index].tax / 100) * this.items[index].rate;
        
                this.items[index].amount = parseFloat(percent + parseFloat(this.items[index].rate)).toFixed(2);
    
            }else{
    
                let qtyRate = this.items[index].rate * this.items[index].qty;
                let percent = (this.items[index].tax / 100) * qtyRate;
                this.items[index].amount = parseFloat(percent + qtyRate).toFixed(2);
            
            }
    
        },
        calItemAmountQty(index){
    
            if(this.items[index].tax > 0){
    
                this.items[index].amount = this.items[index].rate * this.items[index].qty;
                let percent = (this.items[index].tax / 100) * this.items[index].amount;
                this.items[index].amount = parseFloat(percent + this.items[index].amount).toFixed(2);
    
            }else{
    
                this.items[index].amount = parseFloat(this.items[index].rate * this.items[index].qty).toFixed(2);
    
            }
    
        },
        rateChangeEffect(index){
    
            if(!this.items[index].rate){
    
                this.$notify({
                    type: 'warning',
                    title: 'Alert',
                    message: "Item rate field cannot be empty.",
                });
        
                this.items[index].rate = "0.00"
    
            }
    
            if(this.items[index].qty > 1 || this.items[index].tax > 0){
    
                this.items[index].rate = parseFloat(this.items[index].rate).toFixed(2);
        
                this.items[index].amount = this.items[index].rate * this.items[index].qty;
                let percent = (this.items[index].tax / 100) * this.items[index].amount;
                this.items[index].amount = parseFloat(percent + this.items[index].amount).toFixed(2);
    
            }else{
    
                this.items[index].rate = parseFloat(this.items[index].rate).toFixed(2);
        
                this.items[index].amount = this.items[index].rate;
    
            }
            
        },
        calDiscountedTotal(){
    
            let percent = (this.bill.discount / 100) * this.bill.sub_total;
            this.bill.total = percent + this.bill.sub_total;
    
        },
        async validate(action){
    
            if(action == "update"){

                this.$refs.updateBillObserver.validate().then(success => {
                    
                    if(!success){

                        const errors = Object.entries(this.$refs.updateBillObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.updateBillObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    }else{

                        this.saveBill();

                    }

                });

            }
    
        },
        async saveBill(){
    
            this.bill.vendor_id = this.selectedVendor.id
            this.bill.items = this.items
    
            let formData = new FormData();
    
            formData.append('id',this.bill.id);
            formData.append('vendor_id',this.bill.vendor_id);
            formData.append('bill_no',this.bill.bill_no);
    
            if(this.bill.order_no && this.bill.order_no.length > 0 ){
    
                formData.append('order_no',this.bill.order_no);
            
            }
    
            formData.append('bill_date',this.bill.bill_date);
            formData.append('due_date',this.bill.due_date);
            formData.append('discount',this.bill.discount);
            formData.append('sub_total',this.bill.sub_total);
            formData.append('total',this.bill.total);
    
            if(this.bill.attachment && typeof(this.bill.attachment) != 'string'){
    
                formData.append('attachment',this.bill.attachment);
    
            }
    
            for( let i= 0 ; i < this.items.length ; i++ ){
    
                formData.append(`items[${i}][amount]`,this.items[i].amount);
                formData.append(`items[${i}][bill_type]`,this.items[i].bill_type);
                formData.append(`items[${i}][qty]`,this.items[i].qty);
                formData.append(`items[${i}][rate]`,this.items[i].rate);
                formData.append(`items[${i}][tax]`,this.items[i].tax);
                formData.append(`items[${i}][variation_id]`,this.items[i].variation_id);
                formData.append(`items[${i}][name][name]`,this.items[i].name.name);
                formData.append(`items[${i}][name][price]`,this.items[i].name.price);
                formData.append(`items[${i}][name][variation_id]`,this.items[i].name.variation_id);
    
            }
    
            if(this.bill.description && this.bill.description.length > 0){
    
                formData.append('description',this.bill.description);
    
            }
    
            this.isLoading = true
            try{
                let res = await this.$axios.post('/updateBill',formData);
                if(res.data.status_code == '1058'){
        
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });
        
                    this.$store.commit('bills_module/update_bills_list',res.data.bill)
        
                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);
        
                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$router.push({ path: '/expenses-purchases/bill'});

                }
            }catch(error){
    
                if(error.response){

                    if(error.response.data.error.bill_id){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error.bill_id[0],
                        });

                    }else if(error.response.data.error.bill_no){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.bill_no[0],
                        });

                    }else if(error.response.data.error.order_no){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.order_no[0],
                        });

                    }else if(error.response.data.error.vendor_id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error.vendor_id[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){
        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });
        
                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{
        
                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });
        
                    }

                }else{
        
                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });
        
                }
    
            }finally{
    
                this.isLoading = false
    
            }
    
        },
        addLine(){
            
            let item = {
                variation_id:'',
                name:'',
                bill_type:'',
                qty:0,
                rate:"0.00",
                tax:0,
                amount:"0.00",
            }

            if(this.settings){

                item.tax = this.settings.tax

            }

            this.items.push(item);
    
        },
        removeLine(index){

            MessageBox.confirm(
                'Are you sure you want to delete the category?',
                'Warning',
                {
                    type: 'warning',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                }
            ).then(()=>{

                if(this.items.length > 1){
    
                    this.items.splice(index,1);

                }else{

                    this.items = [{
                        variation_id:'',
                        name:'',
                        bill_type:'',
                        qty:0,
                        rate:"0.00",
                        tax:0,
                        amount:"0.00",
                    }]

                }

            }).catch(() => {})

        },
        async loadProducts(query){
            
            if(query){

                this.products = [];

                clearTimeout(this.productsTimer);

                this.productsTimer = setTimeout(async () => {

                    this.multiLoader = true;
                    try{
                        let res = await this.$axios.get('/getAllProducts',{
                            params: {
                                offset: 15,
                                search: query ? query : null
                            }
                        });
                        if(res.data.status_code == "1007"){

                            let tempProduct = [];
                            tempProduct = res.data.products.data;

                            if(tempProduct.length != 0){

                                tempProduct.forEach(product => {

                                    product.variants.forEach( variant =>{

                                        let obj = {
                                            name: variant.name == product.name ? product.name : product.name + " / " + variant.name,
                                            variation_id: variant.id,
                                            price: parseInt(variant.price)
                                        }

                                        if(!this.tempProducts.some((tempProduct) => tempProduct.variation_id == variant.id)){

                                            this.tempProducts.push(obj)

                                        }

                                    });

                                });

                                this.products = this.tempProducts

                            }else{

                                this.tempProducts = [];
                                this.products = [];

                            }

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.multiLoader = false;

                    }

                }, 500);

            }else{

                this.products = [];

                this.multiLoader = true;
                try{
                    let res = await this.$axios.get('/getAllProducts',{
                        params: {
                            offset: 15,
                            search: query ? query : null
                        }
                    });
                    if(res.data.status_code == "1007"){

                        let tempProduct = [];
                        tempProduct = res.data.products.data;

                        if(tempProduct.length != 0){

                            tempProduct.forEach(product => {

                                product.variants.forEach( variant => {

                                    let obj = {
                                        name: variant.name == product.name ? product.name : product.name + " / " + variant.name,
                                        variation_id: variant.id,
                                        price: parseInt(variant.price)
                                    }

                                    if(!this.tempProducts.some((tempProduct) => tempProduct.variation_id == variant.id)){

                                        this.tempProducts.push(obj)

                                    }

                                });

                            });

                            this.products = this.tempProducts

                        }else{

                            this.tempProducts = [];
                            this.products = [];

                        }

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{

                    this.multiLoader = false;

                }

            }
    
        },

        //load vendors method starts here
        async loadVendors(query){

            if(query){

                clearTimeout(this.vendorsTimer);

                this.vendorsTimer = setTimeout(async () => {

                    this.vendorsLoading = true;
                    try{
                        let res = await this.$axios.get("/getVendors",{
                            params:{
                                offset: 15,
                                search: query ? query : null
                            }
                        });
                        if(res.data.status_code == "1046"){

                            this.vendors = res.data.vendors.data
                            this.tempVendors = res.data.vendors.data

                        }
                    }catch(error){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.vendorsLoading = false;

                    }

                }, 500);

            }else{

                this.vendorsLoading = true;
                try{
                    let res = await this.$axios.get("/getVendors",{
                        params:{
                            offset: 15,
                            search: query ? query : null
                        }
                    });
                    if(res.data.status_code == "1046"){

                        this.vendors = res.data.vendors.data
                        this.tempVendors = res.data.vendors.data

                    }
                }catch(error){

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{

                    this.vendorsLoading = false;

                }

            }

        },
        //load vendors method ends here

        handleSelected(data, index){
    
            let foundIndex = this.items.findIndex(item => item.variation_id == data.variation_id)
    
            if(foundIndex == -1){
    
                this.items[index].name = data;
                this.items[index].rate = parseFloat(data.price).toFixed(2);
                this.items[index].variation_id = data.variation_id;
                this.items[index].amount = parseFloat(data.price).toFixed(2);
    
            }else{

                this.$notify({
                    type: 'warning',
                    title: 'Alert',
                    message: 'Item already exists in the list.',
                });
    
                this.items[index].name = ''
    
            }

        },
        handleRemove(data,index){
    
            if(this.items.length > 1){

                this.items.splice(index,1);
            
            }else{

                this.items = [{
                    variation_id:'',
                    name:'',
                    bill_type:'',
                    qty:0,
                    rate:"0.00",
                    tax:0,
                    amount:"0.00",
                }]

            }
    
        },
        goBack(){

            if(this.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async(result) => {
                        
                    this.$router.go(-1)
        
                }).catch(()=>{})

            }else{

                this.$router.go(-1)

            }

        }
    },
    mounted(){
    },
    async beforeMount(){
  
        this.isLoadingBill = true
        
        this.loadVendors(null);

        this.loadProducts(null);

        if(!this.$route.params.bill){
            
            try{
                let billId = this.$route.params.id
                let billRes = await this.$axios.get('/getBill?',{
                    params:{
                        id: billId
                    }
                });
        
                if(billRes.data.status_code == '1056'){
        
                    this.bill = billRes.data.bill
                    this.selectedVendor = billRes.data.bill.vendor
                    this.items = []
                    
                    billRes.data.bill.items.forEach(item=>{

                        this.items.push(JSON.parse(item.item));

                    });
        
                }
            }catch(error){

                this.$router.push('/expenses-purchases/bill')

                if(error.response){

                    if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    }); 

                }
    
            }finally{
    
                this.isLoadingBill = false
    
            }
            
        }else{

            this.bill = this.$route.params.bill
            this.selectedVendor = this.$route.params.bill.vendor
    
            this.items = []

            this.$route.params.bill.items.forEach(item =>{

                this.items.push(JSON.parse(item.item));

            })
            
            this.isLoadingBill = false

        }
      
    },
    beforeDestroy(){
        
    }
};
</script>
<style>
    .viewOrderBox tbody tr td {
        cursor: inherit !important;
    }
    .category-tab-img {
        height: 400px;
        width: 400px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    .category-tab-img:hover {
        border-color: #409EFF;
    }
    .category-tab-img img{
        height: 100%;
        width: 100%;
    }
    .category-tab-img svg{
        position: absolute;
        width: 50px;
        top: 43%;
        left: 0;
        right: 0;
        margin: 0 auto;
        fill: #d9d9d9;
    }
    .category-tab-img:hover svg{
        fill: #409EFF;
    }
    .custom-form.create-section .ql-editor {
        color: #000 !important;
    }
    .ql-editor{
        height: 200px;
    }
    
</style>